module.exports = [{
      plugin: require('/home/travis/build/cdtinney/tinney.dev/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/cdtinney/tinney.dev/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/travis/build/cdtinney/tinney.dev/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"quality":100,"backgroundColor":"transparent","wrapperStyle":"margin: 1rem;"},
    },{
      plugin: require('/home/travis/build/cdtinney/tinney.dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-87415138-3","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/travis/build/cdtinney/tinney.dev/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
